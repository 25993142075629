import { BaseModel } from '../worksheet/BaseModel';

export class FertilizerProduct extends BaseModel {
    name: string;
    applicationCost = 0;
    applicationIncorporation = 0;
    applicationRate = 0;
    displayOrder = 0;
    fertilizerApplicationId: string;
    fertilizerForm = 1;
    fertilizerProductId = 18; // default to 11-52.0 MAP

    constructor(init?: Partial<FertilizerProduct>) {
        super();
        this.name = this.isEn ? 'Product 1' : 'Produit 1';
        Object.assign(this, init);
    }
}
