import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Utils } from '../../../core/utilities/utils';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { MaterialSample } from './material-sample.model';
import { MaterialTestValue } from './material-test-value.model';

export class MaterialType extends BaseModel {
  name: string;
  materialForm = 0;
  materialType = 0;
  worksheetId: string;
  materialSamples: MaterialSample[] = [];

  constructor(init?: Partial<any>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'New material' : 'Nouvelle matière';
    this.materialSamples[0] = new MaterialSample();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, [
        Validators.maxLength(30),
        Validators.minLength(3),
        ValidationFunctions.empty
      ]),
      materialForm: new UntypedFormControl(this.materialForm),
      materialType: new UntypedFormControl(this.materialType),
      notes: new UntypedFormControl(this.notes, [Validators.maxLength(250)]),
      materialSamples: new UntypedFormArray(this.materialSamples.map(s => new UntypedFormGroup({
        dryMatterRange: new UntypedFormControl(s.dryMatterRange),
        dryMatterLow: new UntypedFormControl(s.dryMatterLow),
        dryMatterHigh: new UntypedFormControl(s.dryMatterHigh),
        nutrientValueSource: new UntypedFormControl(s.nutrientValueSource),
        nutrientValueBasis: new UntypedFormControl(s.nutrientValueBasis),
        materialTestValues: new UntypedFormArray(s.materialTestValues.map(t => new UntypedFormGroup({
          testCode: new UntypedFormControl(t.testCode),
          testValue: new UntypedFormControl(t.testValue)
        })))
      })))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new MaterialType({
      id: v.id,
      worksheetId: v.worksheet,
      name: v.name,
      materialSamples: v.materialSamples.map(m => new MaterialSample({
        dryMatterRange: m.dryMatterRange,
        dryMatterLow: m.dryMatterLow,
        dryMatterHigh: m.dryMatterHigh,
        nutrientValueSource: m.nutrientValueSource,
        nutrientValueBasis: m.nutrientValueBasis,
        materialTestValues: m.materialTestValues.map(t => new MaterialTestValue(t.testCode, t.testValue))
      }))
    });
  }
}
