import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../core/utilities/utils';
import { ValidationFunctions } from '../../validators/validation-functions';
import { BaseModel } from '../worksheet/BaseModel';
import { FertilizerProduct } from './plato-fertilizer-product.model';

export class PlatoFertilizerApplication extends BaseModel {

  name: string;
  applicationDate = Utils.getDefaultApplicationDate();
  applicationIncorporation = 0;
  calculationType = 0;
  fieldSectionId: string;
  targetCalciumInMetric = 0;
  targetMagnesiumInMetric = 0;
  targetManganeseInMetric = 0;
  targetNitrogenInMetric = 0;
  targetPhosphateInMetric = 0;
  targetPotashInMetric = 0;
  targetSulfurInMetric = 0;
  targetZincInMetric = 0;
  targetBoronInMetric = 0;
  targetMolybdenumInMetric = 0;
  fertilizerProducts: FertilizerProduct[] = [];
  applicationRate: number;

  constructor(init?: Partial<PlatoFertilizerApplication>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'Fertilizer App' : '	Épan engrais';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      targetCalciumInMetric: new UntypedFormControl(this.targetCalciumInMetric),
      targetMagnesiumInMetric: new UntypedFormControl(this.targetMagnesiumInMetric),
      targetManganeseInMetric: new UntypedFormControl(this.targetManganeseInMetric),
      targetNitrogenInMetric: new UntypedFormControl(this.targetNitrogenInMetric),
      targetPhosphateInMetric: new UntypedFormControl(this.targetPhosphateInMetric, { validators: [Validators.min(0)] }),
      targetPotashInMetric: new UntypedFormControl(this.targetPotashInMetric),
      targetSulfurInMetric: new UntypedFormControl(this.targetSulfurInMetric),
      targetZincInMetric: new UntypedFormControl(this.targetZincInMetric),
      targetBoronInMetric: new UntypedFormControl(this.targetBoronInMetric),
      targetMolybdenumInMetric: new UntypedFormControl(this.targetMolybdenumInMetric),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      applicationDate: new UntypedFormControl(this.applicationDate),
      applicationIncorporation: new UntypedFormControl(this.applicationIncorporation),
      calculationType: new UntypedFormControl(this.calculationType),
      fieldSectionId: new UntypedFormControl(this.fieldSectionId),
      updatedBy: new UntypedFormControl(this.updatedBy),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      fertilizerProducts: new UntypedFormArray(
        this.fertilizerProducts.map(p => new UntypedFormGroup({
          name: new UntypedFormControl(p.name),
          applicationCost: new UntypedFormControl(p.applicationCost),
          applicationRate: new UntypedFormControl(p.applicationRate),
          displayOrder: new UntypedFormControl(p.displayOrder),
          fertilizerApplicationId: new UntypedFormControl(p.fertilizerApplicationId),
          fertilizerForm: new UntypedFormControl(p.fertilizerForm),
          fertilizerProductId: new UntypedFormControl(p.fertilizerProductId)
        }))
      )
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new PlatoFertilizerApplication({
      id: v.id,
      createdBy: v.createdBy,
      targetCalciumInMetric: v.targetCalciumInMetric,
      targetMagnesiumInMetric: v.targetMagnesiumInMetric,
      targetManganeseInMetric: v.targetManganeseInMetric,
      targetNitrogenInMetric: v.targetNitrogenInMetric,
      targetPhosphateInMetric: v.targetPhosphateInMetric,
      targetPotashInMetric: v.targetPotashInMetric,
      targetSulfurInMetric: v.targetSulfurInMetric,
      targetZincInMetric: v.targetZincInMetric,
      targetBoronInMetric: v.targetBoronInMetric,
      targetMolybdenumInMetric: v.targetMolybdenumInMetric,
      name: v.name,
      applicationDate: v.applicationDate,
      applicationIncorporation: v.applicationIncorporation,
      calculationType: v.calculationType,
      fieldSectionId: v.fieldSectionId,
      updatedBy: v.updatedBy,
      updatedDateTime: v.updatedDateTime,
      createdDateTime: v.createdDateTime,
      fertilizerProducts: v.fertilizerProducts.map(p => new FertilizerProduct({
        name: p.name,
        applicationCost: p.applicationCost,
        applicationRate: p.applicationRate,
        displayOrder: p.displayOrder,
        fertilizerApplicationId: p.fertilizerApplicationId,
        fertilizerForm: p.fertilizerForm,
        fertilizerProductId: p.fertilizerProductId
      }))
    });
  }
}
