import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { MaterialTestValue } from './material-test-value.model';

export class MaterialSample extends BaseModel {
  dryMatterRange = 0;
  dryMatterLow = 0;
  dryMatterHigh = 18;
  nutrientValueSource = 1;
  nutrientValueBasis = 0;
  materialTestValues: MaterialTestValue[] = [];

  constructor(init?: Partial<any>) {
    super();
    this.materialTestValues[0] = new MaterialTestValue('P', 0.12);
  }
}
