import { BaseModel } from '../../../shared/models/worksheet/BaseModel';

// tslint:disable-next-line: no-use-before-declare
export class MaterialTestValue extends BaseModel {

  constructor(public testCode: string, public testValue = 0) {
    super();
  }
}

