import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { FertilizerApplicationIncorporation } from '@shared/models/material/fertilizer-application-incorporation.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FertilizerApplicationIncorporationService {

  constructor(private httpClient: HttpClient) { }

  all(): Observable<FertilizerApplicationIncorporation[]> {
    return this.httpClient.get(URLs.FERTILIZER_APPLICATION_INCORPORATION_ENDPOINT) as Observable<FertilizerApplicationIncorporation[]>;
  }
}
